import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RouterHistoryService implements OnDestroy {
    private history: string[] = [];
    private subscriptions: Subscription = new Subscription();

    constructor(private router: Router) {}

    public loadRouting(): void {
        const events = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(({ urlAfterRedirects }: NavigationEnd) => {
                    this.history = [...this.history, urlAfterRedirects];
                }))
            .subscribe();
        this.subscriptions.add(events);
    }

    public getHistory(): string[] {
        return this.history;
    }

    public getPreviousUrl(): string {
        return this.history[this.history.length - 1] || '/';
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
